import config from 'config';
import posthog from 'posthog-js'
import { setTranslation } from "@mozioinc/transfer-widgets";
import { setConfig } from 'mz-sdk';

/**
 * Inject tag in the head tag
 */
const injectToHead = (tag, attrs) => {
  const newTag = document.createElement(tag);
  Object.keys(attrs).forEach(k => {
    newTag[k] = attrs[k];
  });
  document.getElementsByTagName('head')[0].appendChild(newTag);
};


/**
 * Google Tag Manager
 */
const initGTM = () => {
  if (!config.TAG_MANAGER_KEY) return;

  // Async initialization of GTM
  window.dataLayer = window.dataLayer || [];

  /* eslint-disable */
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
    var f = d.getElementsByTagName(s)[0],j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f.nextSibling);
  })(window, document, 'script', 'dataLayer', config.TAG_MANAGER_KEY);
  /* eslint-enable */
};

/**
 * Zendesk initialization
 */

const initZendesk = () => {
  if (config.ZENDESK_ENABLED) {

    window.zESettings = {
      webWidget: {
        chat: {
          tags: ['website', `partner-${config.PARTNER_REF || 'mozio'}`]
        },
        color: {
          theme: config[config.HELP_CHAT_THEME_COLOR],
          button: config[config.HELP_BUTTON_COLOR]
        },
        helpCenter: {
          originalArticleButton: false
        }
      }
    }

    // SNIPPET MODIFIED: Integrate only when the user consent
    if (!window.zEmbed) {
      const zEQueue = [];
      window.zEmbed = (...args) => zEQueue.push(args)
      window.zE = window.zE || window.zEmbed

      /* eslint-disable */
      let n,o,d,i,s,r=document.createElement("iframe");
      r.src="javascript:false",r.title="",r.role="presentation",
      (r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],
      d.parentNode.insertBefore(r,d),
      i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}
      o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),
      e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,
      this.zendeskHost=config.ZENDESK_HOST,this.zEQueue=zEQueue,this.body.appendChild(e)},
      o.write('<body onload="document._l();">'),o.close()
      /* eslint-enable */
    }

    // FIX: it's a temporary solution for https://mozio.atlassian.net/browse/DEV-1416
    // window.zE('webWidget', 'setLocale', locale);
  }
};


/**
 * Initialize loggly
 */
const initLoggly = () => {
  if (config.LOGGLY_KEY) {
    window._LTracker = window._LTracker || [];
    window._LTracker.push({
      logglyKey: config.LOGGLY_KEY,
      sendConsoleErrors: true,
    });

    injectToHead('script', {
      async: true,
      src: 'https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js',
    });
  }
};

/**
 * Stripe API initialization
 */
export const initStripe = () => {
  if (typeof window.Stripe !== 'undefined') {
    return Promise.resolve();
  }
  return new Promise(r => {
    injectToHead('script', {
      async: true,
      src: 'https://js.stripe.com/v3/',
      onload: r
    });
  });
};

const initPosthog = () => {
  const { APP_VERSION, MOZIO_PUBLIC_POSTHOG_KEY, MOZIO_PUBLIC_POSTHOG_HOST } =
  process.env;

  const refParam = new URLSearchParams(window.location.search).get('ref');

  if (refParam === 'aws-syntethics-canaries' || refParam === 'aws-synthetics-canaries') {
    return;
  }

  if (!MOZIO_PUBLIC_POSTHOG_KEY || !MOZIO_PUBLIC_POSTHOG_HOST) {
    console.error(
      'Posthog integration is not configured properly so it will not be loaded.'
    );
    return;
  }

  posthog.init(MOZIO_PUBLIC_POSTHOG_KEY, {
    api_host: MOZIO_PUBLIC_POSTHOG_HOST,
    debug: config.ENV === 'dev',
    disable_session_recording: true,
    loaded: (posthog) => {
      posthog.register({
        white_label_id: config.ID,
        white_label_name: config.NAME || 'mozio',
        partner_ref: config.PARTNER_REF || 'mozio',
        app_version: APP_VERSION,
        product: 'Transfer',
      });

      setConfig({
        DISTINCT_ID: posthog.get_distinct_id(),
      });
    },
  });
};


// Init analytics only
export const initTrackers = () => {
  if (config.ENV === 'dev') {
    return;
  }

  initLoggly();

  if (!window.Osano?.cm) {
    console.error('Osano Consent Manager is not loaded.');
    return;
  }

  window.Osano.cm.addEventListener('osano-cm-consent-saved', (consent) => {
    if (consent.ANALYTICS === 'ACCEPT') {
      initPosthog();
      posthog.opt_in_capturing();
      initGTM();
    } else {
      posthog.opt_out_capturing();
    }
  });
};

// All together
export const initOthers = (locale) => {
  setTranslation();

  if (config.ENV === 'dev' || process.env.CONFIG_ENV === "staging") {
    return
  }

  initZendesk(locale);
};
